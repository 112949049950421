import { makeStyles } from '@material-ui/styles'
import SelectableCard from 'core/components/SelectableCard'
import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import React from 'react'

interface PluginCardProps {
  title: string
  description: string
  onClick: () => void
  icon: React.ReactNode
  active?: boolean
  color?: string // color for the half circle
  disabled?: boolean
}

export default function PluginCard({
  title,
  description,
  onClick,
  icon,
  active = false,
  color = '#3ACCC533',
  disabled = false,
}: PluginCardProps) {
  const classes = useStyles({ color })
  return (
    <SelectableCard
      id="virtualizedClusters"
      onClick={onClick}
      active={active}
      className={classes.card}
      withCustomBody
      disabled={disabled}
    >
      <div className={classes.halfCircle} />
      <div className={classes.cardContent}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.textContainer}>
          <Text variant="subtitle1">{title}</Text>
          <Text variant="body2">{description}</Text>
        </div>
      </div>
    </SelectableCard>
  )
}

const useStyles = makeStyles<Theme, { color?: string }>((theme) => ({
  card: {
    width: '372px',
    height: '110px',
    boxSizing: 'border-box',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(1),
    display: 'grid',
    alignItems: 'center',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[500]}`,
    },
  },
  halfCircle: {
    width: '168px',
    height: '168px',
    background: ({ color }) => color,
    borderRadius: '50%',
    position: 'absolute',
    left: '-23%',
    zIndex: 1,
  },
  icon: {
    height: '56px',
    width: '56px',
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 260px',
    gridGap: theme.spacing(3),
    alignItems: 'center',
    position: 'relative',
    zIndex: 2,
    marginLeft: theme.spacing(0.5),
  },
  textContainer: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
}))
