import { makeStyles } from '@material-ui/styles'
import { AppPlugins } from 'app/constants'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import { PluginSection } from 'core/plugins/model'
import Theme from 'core/themes/model'
import { isKaapiEnabled as isKaapiEnabledFn, isKaapiTokenAvailable } from 'core/utils/helpers'
import React, { useMemo } from 'react'
import { noop } from 'utils/fp'
import PluginCard from './PluginCard'
import SpinLogo from './SpinLogo'

const PLUGIN_COLOR_MAP = {
  [AppPlugins.OpenStack]: '#3ACCC533',
  [AppPlugins.Kubernetes]: '#326DE633',
}

interface PluginMenuProps {
  open: boolean
  toggleMenu: () => void
  currentPluginId: string
  options: PluginSection[]
  onPluginChange?: (pluginId: string) => void
  collapse?: boolean
}

export default function PluginMenu({
  open,
  toggleMenu,
  onPluginChange,
  currentPluginId,
  options,
  collapse = false,
}: PluginMenuProps) {
  const isKaapiEnabled = isKaapiEnabledFn()
  const classes = useStyles({ open, collapse, isKaapiEnabled })

  const currentPluginName = useMemo(
    () =>
      isKaapiEnabled
        ? options.find((option) => option.id === currentPluginId)?.name
        : 'Private Cloud Director',
    [options, currentPluginId],
  )

  return (
    <div className={classes.pluginMenuContainer}>
      <div
        className={clsx(classes.anchor, { active: open })}
        onClick={isKaapiEnabled ? toggleMenu : noop}
      >
        <SpinLogo active={false} />
        <Text variant="subtitle1" className={classes.productTitle}>
          {currentPluginName}
        </Text>
        {isKaapiEnabled && (
          <FontAwesomeIcon solid size="xs" className={classes.chevronIcon}>
            chevron-down
          </FontAwesomeIcon>
        )}
      </div>
      {isKaapiEnabled && open && (
        <div className={classes.menu}>
          {options.map((option) => {
            if (option.id === AppPlugins.MyAccount) return null
            const disableK8sPlugin = option.id === AppPlugins.Kubernetes && !isKaapiTokenAvailable()
            return (
              <PluginCard
                key={option.id}
                title={option.name}
                description={option.description}
                onClick={() => onPluginChange(option.id)}
                icon={option.icon}
                active={false}
                color={PLUGIN_COLOR_MAP[option.id]}
                disabled={disableK8sPlugin}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles<
  Theme,
  { open: boolean; collapse?: boolean; isKaapiEnabled?: boolean }
>((theme) => ({
  pluginMenuContainer: {
    gridArea: 'nav-header',
    '& .menu-popover': {
      padding: 8,
      backgroundColor: theme.components.sidebar.background,
      borderColor: theme.components.sidebar.border,
    },
  },
  anchor: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    gridGap: theme.spacing(1),
    alignItems: 'center',
    justifyItems: 'stretch',
    paddingLeft: theme.spacing(2),
    height: 64,
    borderBottom: ({ open }) => (open ? 'none' : `1px solid ${theme.components.sidebar.border}`),
    cursor: ({ isKaapiEnabled }) => (isKaapiEnabled ? 'pointer' : 'default'),
    '&.active': {
      background: theme.components.card.background,
    },
    '& #logoDefault': {
      justifySelf: 'center',
    },
  },
  productTitle: {
    color: theme.components.sidebar.activeText,
    textTransform: 'capitalize',
    display: ({ collapse }) => (collapse ? 'none' : 'unset'),
    whiteSpace: 'nowrap',
    fontSize: 18,
  },
  chevronIcon: {
    position: 'absolute',
    left: '256px',
    display: ({ collapse }) => (collapse ? 'none' : 'unset'),
    transform: ({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)'),
  },
  menuContainer: {
    '& .menu-popover': {
      padding: '8px',
      top: 'unset',
      left: 'unset',
    },
  },
  menu: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
    zIndex: 100,
    position: 'absolute',
    padding: theme.spacing(3),
    backgroundColor: theme.components.card.background,
  },
}))
