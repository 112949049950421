import { useCustomTheme } from 'core/themes/ThemeManager'
import { formatNumberWithLabel } from 'core/utils/helpers'
import React from 'react'

const StackAreaChartYAxisLabel = (props) => {
  const { x, y, payload, customText } = props
  const [theme] = useCustomTheme()
  const defaultConfig = {
    dyTextSpacing: 1,
    typography: theme?.typography?.caption3,
    fontWeight: 400,
    fillColor: theme?.components?.table?.headColor,
  }

  const formattedValue = formatNumberWithLabel(payload?.value)
  const labelText = `${formattedValue.value}${formattedValue.label} ${customText}`
  const transform = `translate(3px)`

  return (
    <text
      x={x}
      y={y}
      dy={defaultConfig.dyTextSpacing}
      style={{
        ...defaultConfig.typography,
        fontWeight: defaultConfig.fontWeight,
        transform: transform,
      }}
      fill={defaultConfig.fillColor}
      textAnchor="end" // Right-align the text
    >
      {labelText}
    </text>
  )
}

export default StackAreaChartYAxisLabel
