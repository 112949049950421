import { AppPlugins, CustomerTiers, ssoRedirectUrl } from 'app/constants'
import { pathStrOr } from 'utils/fp'
import { sessionStoreKey } from 'core/session/sessionReducers'
import store from 'app/store'

export const isKaapiEnabled = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return pathStrOr(true, 'enable_kaapi', features)
}

export const isKaapiTokenAvailable = () => {
  const state = store.getState()
  const { jwtToken } = state[sessionStoreKey]
  return !!jwtToken
}

// This function is used to get the active PCD tenant name from the redux store
export const getActiveTenantName = () => {
  const state = store.getState()
  const { activeTenant } = state[sessionStoreKey]
  return activeTenant || ''
}

export const isDecco = (features) => pathStrOr(false, 'experimental.kplane', features)

export const isDeccoEnv = () => {
  const state = store.getState()
  const { features } = state[sessionStoreKey]
  return isDecco(features)
}

export const isProductionEnv = process.env.NODE_ENV === 'production'

export const showZendeskChatOption = (lastStack, customerTier) =>
  (lastStack === AppPlugins.Kubernetes || lastStack === AppPlugins.MyAccount) &&
  customerTier === CustomerTiers.Enterprise &&
  isProductionEnv

export const convertListOfObjectsToMap = (key: string, objects: any[]) => {
  const result = new Map()
  for (const obj of objects) {
    if (obj[key]) {
      result.set(obj[key], obj)
    }
  }
  return result
}

export const devEnabled = () => window.localStorage.enableDevPlugin === 'true'

const READONLY_ROLES = ['reader']
export const isReadonlyUser = () => {
  const state = store.getState()
  const { userDetails } = state[sessionStoreKey]
  return READONLY_ROLES.includes(userDetails?.role)
}

export const isDefaultDomain = (domain?: string) => {
  return (
    !domain ||
    domain.trim() === '' ||
    domain.trim() === 'Default Domain' ||
    domain?.trim()?.toLowerCase() === 'default'
  )
}

export const getSSORedirectUrl = (domain?: string) => {
  if (isDefaultDomain(domain)) {
    return ssoRedirectUrl
  } else {
    return ssoRedirectUrl.replace('IDP1', domain)
  }
}

export const formatNumberWithLabel = (value) => {
  if (value >= 1e12) {
    const valueInT = (value / 1e12).toFixed(0)
    return { value: valueInT, label: 'T' }
  } else if (value >= 1e9) {
    const valueInB = (value / 1e9).toFixed(0)
    return { value: valueInB, label: 'B' }
  } else if (value >= 1e6) {
    const valueInM = (value / 1e6).toFixed(0)
    return { value: valueInM, label: 'M' }
  } else if (value >= 1e3) {
    const valueInK = (value / 1e3).toFixed(0)
    return { value: valueInK, label: 'k' }
  } else if (Number.isInteger(value)) {
    return { value: value.toString(), label: '' }
  } else {
    return { value: value.toFixed(1), label: '' }
  }
}

export const getTimeRangeData = (selectedTimeRange: string, isModalView?: boolean) => {
  const currentDate = new Date()
  let endDate: Date
  let startDate: Date
  let steps: string
  let interval: number
  switch (selectedTimeRange) {
    case 'last_one_hour': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 1) // Set startDate to one hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = '5m'
      break
    }
    case 'last_3_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 6) // Set startDate to 6 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '7m' : '15m'
      break
    }
    case 'last_6_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 6) // Set startDate to 6 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '15m' : '30m'
      break
    }
    case 'last_12_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 12) // Set startDate to 12 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '30m' : '1h'
      break
    }
    case 'last_24_hours': {
      const now = new Date(currentDate)
      startDate = new Date(now)
      startDate.setHours(now.getHours() - 24) // Set startDate to 24 hour before current time
      endDate = new Date(now) // Set endDate to the current time
      steps = isModalView ? '1h' : '2h'
      break
    }
    case 'today': {
      startDate = new Date(currentDate)
      startDate.setHours(0, 0, 0, 0) // Set the time to the start of the day (00:00:00.000)
      endDate = new Date(currentDate)
      steps = '4h'
      break
    }
    case 'yesterday': {
      const yesterday = new Date(currentDate)
      yesterday.setDate(yesterday.getDate() - 1)
      startDate = new Date(yesterday)
      startDate.setHours(0, 0, 0, 0) // Set to 12:00:00 AM (start of the day)
      endDate = new Date(yesterday)
      endDate.setHours(23, 59, 59, 999)
      steps = '4h'
      break
    }
    case 'last_week': {
      const currentDayOfWeek = currentDate.getDay()
      const startOfWeek = new Date(currentDate)
      startOfWeek.setDate(currentDate.getDate() - currentDayOfWeek - 6)
      startOfWeek.setHours(0, 0, 0, 0) // Calculate the end of the last week (Sunday at 11:59 PM)

      const endOfWeek = new Date(currentDate)
      endOfWeek.setDate(currentDate.getDate() - currentDayOfWeek)
      endOfWeek.setHours(23, 59, 59, 999)

      startDate = new Date(startOfWeek.getTime())
      endDate = new Date(endOfWeek.getTime())

      steps = '1d'
      break
    }
    case 'last_month': {
      const currentMonth = currentDate.getMonth()
      const currentYear = currentDate.getFullYear()
      const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1, 0, 0, 0, 0) // Calculate the last day of the previous month

      const lastDayOfPreviousMonth = new Date(currentYear, currentMonth, 0, 23, 59, 59, 999)

      startDate = new Date(firstDayOfPreviousMonth.getTime())
      endDate = new Date(lastDayOfPreviousMonth.getTime())
      steps = '1d'
      interval = 4
      break
    }
    case 'last_7_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 6)
      endDate = new Date(currentDate)
      steps = '1d'
      break
    }
    case 'last_30_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 29)
      endDate = new Date(currentDate)
      steps = '1d'
      interval = 4
      break
    }
    case 'last_60_days': {
      startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - 59)
      endDate = new Date(currentDate)
      steps = '1d'
      interval = 8
      break
    }
    default: {
      // Handle an unknown or default case
      startDate = new Date(currentDate) // Default to current date and time
      endDate = new Date(currentDate)
      steps = '4h'
      break
    }
  }

  return {
    startTimeEpoch: startDate?.getTime() / 1000,
    endTimeEpoch: endDate?.getTime() / 1000,
    steps,
    interval,
  }
}
