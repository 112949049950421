const colors = {
  grey: {
    '000': '#ffffff',
    '50': '#FAFAFC',
    '100': '#f5f5f9',
    '150': '#EEEEF2',
    '200': '#e6e6ea',
    '300': '#b6b6c0',
    '400': '#CDD0D4',
    '500': '#868696',
    '600': '#56566B',
    '650': '#4A4A61',
    '700': '#3d3d57',
    '750': '#31314B',
    '800': '#25253f',
    '900': '#0d0d28',
  },
  blue: {
    '100': '#f3fbfe',
    '200': '#cceffc',
    '300': '#82d4f2',
    '500': '#00abe8',
    '700': '#0089c7',
    '900': '#005992',
  },
  codeBlue: {
    '500': '#00AAB4',
  },
  pink: {
    '100': '#FCE6EF',
    '200': '#BF1E00',
    '300': '#f26aa6',
    '500': '#d82071',
    '700': '#8a003c',
    '800': '#F2696A',
    '900': '#6d0030',
  },
  yellow: {
    '100': '#FFF8E9',
    '200': '#FFECBE',
    '300': '#ffd7a2',
    '500': '#ffbf26',
    '700': '#f0aa00',
    '900': '#bf8700',
  },
  k8sBlue: {
    '300': '#9bacfd',
    '500': '#3e5ff5',
    '700': '#011ea4',
    '900': '#001782',
  },
  red: {
    '100': '#FFECE9',
    '200': '#F0AA00',
    '300': '#ff9f8e',
    '400': '#665200',
    '500': '#ff4826',
    '700': '#f02500',
    '900': '#bf1e00',
  },
  green: {
    '100': '#E5F2EA',
    '300': '#B3D8C2',
    '500': '#007D33',
  },
  orange: {
    '100': '#FFF3E5',
    '500': '#ff8a00',
  },
  aws: {
    300: '#FFB74D',
    500: '#FF9800',
    700: '#F57C00',
  },
  azure: {
    300: '#AEE0FF',
    500: '#4AA3DF',
    700: '#1E699C',
  },
  googleYellow: {
    500: '#F4B400',
  },
  grape: {
    800: '#6527AE',
    600: '#8452BE',
    400: '#A37DCE',
    200: '#E0D4EF',
    50: '#F9F5FF',
  },
  purple: {
    800: '#8813A0',
    600: '#A042B3',
    400: '#B871C6',
    200: '#E7D0EC',
    50: '#FBF7FF',
  },
  byzantanBlue: {
    800: '#2F6EBA',
    600: '#011EA4',
    400: '#3E5FF5',
    200: '#9BACFD',
    50: '#F0F2FF',
  },
  skyBlue: {
    800: '#005977',
    600: '#007196',
    400: '#2CC3F3',
    200: '#91E2FD',
    50: '#EDF6FF',
  },
  teal: {
    50: '#E7F4F1',
    100: '#3BA489',
    200: '#D0E5E3',
    300: '#109372',
    400: '#60B59F',
  },
  peach: {
    50: '#F9E8E5',
    100: '#FDF7E5',
  },
  palette: {
    700: '#F4D9DC',
  },
  beige: {
    100: '#F8ECDF',
  },
}
export default colors
