import React from 'react'
import { styled } from '@material-ui/styles'

import Text from 'pf9-ui-components/built/elements/Text'
import Theme from 'core/themes/model'
import { convertToKebabCase } from 'utils/misc'

interface Keys {
  name: string
  legendName: string
  color?: string
}
interface CustomTooltipProps {
  active?: boolean
  payload?: []
  label?: string
  keys: Keys[]
  yAxisPostfixLabel?: string
}

const StackedAreaChartTooltip = ({
  active,
  payload,
  label,
  keys,
  yAxisPostfixLabel,
}: CustomTooltipProps) => {
  if (active && payload) {
    // Need data from both payload (count) & keys (icon, color)
    const countByKey = payload.reduce(
      (
        acc: { [x: string]: any },
        value: { dataKey: string | number; payload: { [x: string]: any } },
      ) => {
        acc[value?.dataKey] = value?.payload[value?.dataKey]
        return acc
      },
      {},
    )
    return (
      <CustomTooltip>
        <CustomTooltipHeader
          data-testid="tooltip-label"
          variant="caption3"
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          {label}
        </CustomTooltipHeader>
        {keys?.map(({ name, legendName, color }) => (
          <div key={name}>
            <CustomTooltipLabel>
              <TooltipIcon id={`${convertToKebabCase(name)}-color`} color={color} />
              <Text
                data-testid={`${convertToKebabCase(name)}-legend`}
                variant="caption3"
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
              >
                {legendName}
              </Text>
            </CustomTooltipLabel>
            <CustomTooltipCount
              data-testid={`${convertToKebabCase(name)}-count`}
              variant="caption2"
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              {countByKey[name]} {yAxisPostfixLabel ? yAxisPostfixLabel : 'cores'}
            </CustomTooltipCount>
          </div>
        ))}
      </CustomTooltip>
    )
  }

  return null
}

export default StackedAreaChartTooltip

const CustomTooltip = styled('div')<Theme>(({ theme }) => ({
  display: 'inline-flex',
  padding: '4px 16px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: 4,
  border: `1px solid ${theme.palette.grey[200]}`,
  background: theme.palette.grey[50],
}))
const CustomTooltipHeader = styled(Text)({
  paddingTop: '4px',
})

const CustomTooltipLabel = styled('div')<Theme>(({ theme }) => ({
  flexGrow: 0,
  marginTop: theme.spacing(1),
}))

const CustomTooltipCount = styled(Text)<Theme>(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const TooltipIcon = styled('span')<Theme>(({ theme, color }) => ({
  display: 'inline-block',
  width: 8,
  height: 8,
  borderRadius: '50%',
  marginRight: theme.spacing(1),
  backgroundColor: color,
}))
