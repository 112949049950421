import React from 'react'

import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { styled } from '@material-ui/styles'
import { useCustomTheme } from 'core/themes/ThemeManager'
import StackAreaChartYAxisLable from './StackAreaChartYAxisLable'

type AreaChartEntry<T extends string, V extends string> = { [P in T | V]: any }

export interface AreaChartType<T> {
  stopOpacityFirst?: string | number
  offsetSecond?: string | number
  stopOpacitySecond?: string | number
  offsetFirst?: string | number
  name: T
  legendName: string
  color: string
  icon?: string
}

// types should be a list of strings that also show up in AreaChartEntry as a property
interface Props<T extends string, V extends string> {
  values: Array<AreaChartEntry<T, V>>
  width?: number
  height?: number
  xAxis: T
  keys: Array<AreaChartType<V>>
  responsive?: boolean
  responsiveHeight?: number
  verticalAxisLines?: boolean
  horizontalAxisLines?: boolean
  customTooltip?: JSX.Element
  showLegend?: boolean
  showXAxisTick?: boolean
  verticalAlign?: 'top' | 'middle' | 'bottom'
  customLegend?: JSX.Element
  xAxisTickFontSize?: number
  yAxisTickFontSize?: number
  cartesianGrid?: boolean
  dx?: number
  dy?: number
  angle?: number
  ticks?: any
  yAxisPostfixLabel?: string
  axisLine?: boolean
}

// Todo: Instead of a set width and height, also allow for percents
function CustomStackedAreaChart<Axis extends string, Types extends string>({
  values,
  keys,
  xAxis,
  verticalAlign = 'bottom',
  verticalAxisLines = false,
  horizontalAxisLines = true,
  cartesianGrid = true,
  responsive = false,
  showLegend = false,
  showXAxisTick = false,
  axisLine = true,
  responsiveHeight = 250,
  customTooltip = undefined,
  customLegend = undefined,
  xAxisTickFontSize,
  yAxisTickFontSize,
  dx,
  dy,
  angle,
  yAxisPostfixLabel,
  ticks,
}: Props<Axis, Types>) {
  // old theme to access palette
  const [theme] = useCustomTheme()
  // const theme = useSelector(prop<string, ThemeReducer>('theme'))
  const defaultConfig = {
    defaultStrokeColor: theme.palette.blue[700],
    defaultStroke: 'rgba(96, 96, 96, 0.5)',
    cartesianGridStroke: theme?.components?.table?.border,
    xAxisFillColor: theme?.components?.table?.headColor,
    yAxisTopSpacing: theme.spacing(2.5),
    tickLineStroke: theme?.components?.table?.border,
    tickFillColor: theme?.components?.table?.headColor,
    transform: 'translate(-12px, 0px)',
    xAxisTickFontSize: 10,
    yAxisTickFontSize: 12,
    yAxisTickSize: 8,
    defaultStopOpacityFirst: 0.15,
    defaultStopOpacitySecond: 0.0,
  }

  const renderAreaChart = () => {
    const gradientDefs = keys?.map((key, index) => (
      <linearGradient key={index} id={`color${key?.name}`} x1="0" y1="0" x2="0" y2="1">
        <stop
          offset={key?.offsetFirst ? key?.offsetFirst : '0%'}
          stopColor={key?.color ? key?.color : defaultConfig.defaultStrokeColor}
          stopOpacity={
            key?.stopOpacityFirst ? key?.stopOpacityFirst : defaultConfig.defaultStopOpacityFirst
          }
        />
        <stop
          offset={key?.offsetSecond ? key?.offsetSecond : '113.39%'}
          stopColor={key?.color ? key?.color : defaultConfig.defaultStrokeColor}
          stopOpacity={
            key?.stopOpacitySecond ? key?.stopOpacitySecond : defaultConfig.defaultStopOpacitySecond
          }
        />
      </linearGradient>
    ))

    const areaComponents = keys?.map((key, index) => (
      <Area
        key={index}
        name={key?.legendName}
        type="monotone"
        dataKey={key?.name}
        stackId={key?.name}
        stroke={key?.color ? key?.color : defaultConfig.defaultStrokeColor}
        strokeWidth={1}
        fill={`url(#color${key?.name})`}
        activeDot={{ strokeWidth: 2, r: 4, stroke: defaultConfig.defaultStroke }}
      />
    ))
    return (
      <AreaChart data={values} margin={{ top: 0, right: 0, left: -35, bottom: 0 }}>
        {cartesianGrid && (
          <CartesianGrid
            vertical={verticalAxisLines}
            horizontal={horizontalAxisLines}
            stroke={defaultConfig.cartesianGridStroke}
            strokeDasharray="3 3"
          />
        )}
        <XAxis
          tick={
            showXAxisTick && {
              fontSize: xAxisTickFontSize ? defaultConfig.xAxisTickFontSize : 12,
              fill: defaultConfig.xAxisFillColor,
            }
          }
          dx={dx}
          dy={dy}
          axisLine={axisLine}
          angle={angle}
          dataKey={xAxis}
          allowDataOverflow
        />
        <YAxis
          padding={{
            top: defaultConfig.yAxisTopSpacing,
          }}
          tickLine={{
            stroke: defaultConfig.tickLineStroke,
            strokeWidth: 1,
          }}
          axisLine={axisLine}
          tick={
            <StackAreaChartYAxisLable customText={yAxisPostfixLabel ? yAxisPostfixLabel : ''} />
          }
          tickSize={defaultConfig.yAxisTickSize}
          allowDataOverflow
        />
        <Tooltip
          cursor={{ stroke: defaultConfig.defaultStroke, strokeDasharray: '6' }}
          content={customTooltip}
        />
        {showLegend && (
          <Legend
            content={customLegend}
            verticalAlign={verticalAlign}
            wrapperStyle={{ bottom: '-16px' }} //TODO :: quick fix need to check proper solution for the same.
          />
        )}
        <defs>{gradientDefs}</defs>
        {areaComponents}
      </AreaChart>
    )
  }

  return responsive ? (
    <ResponsiveContainer width="100%" height={responsiveHeight}>
      {renderAreaChart()}
    </ResponsiveContainer>
  ) : (
    <ChartContainer>{renderAreaChart()}</ChartContainer>
  )
}

export default CustomStackedAreaChart

const ChartContainer = styled('div')({
  '& g.recharts-cartesian-grid-horizontal > line:last-child': {
    display: 'none',
  },
})
